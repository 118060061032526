<template>
    <certifications-component/>
</template>

<script>
    import CertificationsComponent from "@/components/admin/catalogs/CertificationsComponent";
    
    export default {
        name: "Certifications",
        title: "Certificaciones | Turismo BC",
        components: {CertificationsComponent},
    }
</script>

<style scoped>

</style>